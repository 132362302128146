import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";

export const BACKTEST = [
    { pair: "USDCHF", tf: "30m", "0": 53, "1.1": 27, "2.2": 4, "3.3": 5, "4.5": 11 },
    { pair: "GBPNZD", tf: "15m", "0": 66, "1.1": 11, "2.2": 10, "3.3": 3, "4.5": 10 },
    { pair: "GBPNZD", tf: "30m", "0": 56, "1.1": 17, "2.2": 10, "3.3": 4, "4.5": 13 },
    { pair: "GBPCAD", tf: "15m", "0": 57, "1.1": 21, "2.2": 5, "3.3": 5, "4.5": 12 },
    { pair: "EURUSD", tf: "15m", "0": 60, "1.1": 11, "2.2": 9, "3.3": 7, "4.5": 13 },
    { pair: "WTI", tf: "15m", "0": 50, "1.1": 19, "2.2": 12, "3.3": 7, "4.5": 12 },
    { pair: "USDJPY", tf: "15m", "0": 64, "1.1": 12, "2.2": 7, "3.3": 3, "4.5": 14 },



]


const scenarios = ["0", "1.1", "2.2", "3.3", "4.5"];
const profitFactor = {
    "0": 1 / 4.5,
    "1.1": (1 + 1.1) / (4.5 - 1.1),
    "2.2": (1 + 2.2) / (4.5 - 2.2),
    "3.3": (1 + 3.3) / (4.5 - 3.3),
    "4.5": 0
}

const BacktestTable = ({ backtests, defaultRisk }) => {
    const [riskPerOrder, setRiskPerOrder] = useState({
        "0": defaultRisk[0],
        "1.1": defaultRisk[1],
        "2.2": defaultRisk[2],
        "3.3": defaultRisk[3],
    });
    const profitPerOrder = {
        "0": riskPerOrder["0"] * profitFactor["0"],
        "1.1": riskPerOrder["1.1"] * profitFactor["1.1"],
        "2.2": riskPerOrder["2.2"] * profitFactor["2.2"],
        "3.3": riskPerOrder["3.3"] * profitFactor["3.3"],
        "4.5": 0
    }

    const baseline = {
        ...profitPerOrder,
        "1.1": profitPerOrder["0"] + profitPerOrder["1.1"],
        "2.2": profitPerOrder["0"] + profitPerOrder["1.1"] + profitPerOrder["2.2"],
        "3.3": profitPerOrder["0"] + profitPerOrder["1.1"] + profitPerOrder["2.2"] + profitPerOrder["3.3"],
        "4.5": -320
    }

    const calculateStats = (bt, outcomes) => {
        const totalTrades = scenarios.reduce((acc, key) => acc + bt[key], 0);
        const winTrades = scenarios.reduce((acc, key) => {
            if (outcomes[key] > 0) {
                return acc + bt[key];
            }
            return acc;
        }, 0);

        const winRate = (winTrades / totalTrades) * 100;

        const totalPnL = scenarios.map((key) => bt[key] * outcomes[key]);
        const sumPnL = totalPnL.reduce((acc, val) => acc + val, 0);
        const avgTrade = sumPnL / totalTrades;

        const profits = totalPnL.filter((v) => v > 0).reduce((a, b) => a + b, 0);
        const losses = -1 * totalPnL.filter((v) => v < 0).reduce((a, b) => a + b, 0);
        const profitFactor = losses !== 0 ? Math.abs(profits / losses) : "∞";

        return { winRate, profitFactor, avgTrade, sumPnL, totalTrades, profits, losses, totalPnL };
    };
    const totalStats = scenarios.reduce(
        (acc, key) => {
            acc[key] = backtests.reduce((total, bt) => total + bt[key], 0);
            return acc;
        },
        {}
    );
    const cumulativeStats = scenarios.map((key, index) => {
        return scenarios.slice(0, index + 1).reduce((acc, key) => acc + totalStats[key], 0);
    });
    const globalTotalTrades = scenarios.reduce((acc, key) => acc + totalStats[key], 0);
    const overallStats = backtests.reduce(
        (acc, bt) => {
            const stats = calculateStats(bt, baseline);
            acc.winRate += stats.winRate;
            acc.totalPnL += stats.sumPnL;
            acc.pnls.push(stats.sumPnL);
            acc.profits += stats.profits;
            acc.losses += stats.losses;

            return acc;
        },
        { ...totalStats, winRate: 0, avgTrade: 0, totalPnL: 0, profits: 0, losses: 0, pnls: [] }
    );

    const totalBacktests = backtests.length;
    const medianPnL = overallStats.pnls.sort((a, b) => a - b)[
        Math.floor(overallStats.pnls.length / 2)
    ];

    useEffect(() => {
        setRiskPerOrder(prev => ({
            ...prev,
            "3.3": Math.max(0, 320 - prev["0"] - prev["1.1"] - prev["2.2"]),
        }))
    }, [riskPerOrder["0"], riskPerOrder["1.1"], riskPerOrder["2.2"]]);

    const totalRisk = Object.keys(riskPerOrder).reduce((acc, key) => acc + parseInt(riskPerOrder[key]), 0);

    return (
        <div className="overflow-x-auto py-4">

            <table className="w-full border-collapse border border-gray-300 text-left">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="border p-2">BT</th>
                        {scenarios.map((scenario) => (
                            <th key={scenario} className="border p-2">
                                <div>{scenario}</div>
                                {scenario !== "4.5" && <div>
                                    <Input value={riskPerOrder[scenario]} type="range" max="320" step="5" min="0" disabled={scenario === "3.3"} onChange={(e) => setRiskPerOrder(prev => ({ ...prev, [scenario]: e.target.value }))} />
                                </div>}
                                <div className={totalRisk !== 320 ? "text-danger": "text-white"}>Risk = {riskPerOrder[scenario]}</div>
                                <div>Factor = {profitFactor[scenario].toFixed(2)}</div>
                                <div>Win = {profitPerOrder[scenario].toFixed(1)}</div>
                                <div>CumWin ={baseline[scenario].toFixed(1)}</div>
                            </th>
                        ))}
                        <th className="border p-2">Tot</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-gray-300 font-bold">
                        <td></td>
                        {scenarios.map((scenario, index) => (
                            <td key={scenario} className="border p-2">
                                <div>{totalStats[scenario]}</div>
                                <div>{(totalStats[scenario] / globalTotalTrades * 100).toFixed(1)}%</div>

                                <div className="pt-2">{totalStats[scenario]}x{baseline[scenario].toFixed(1)}={(totalStats[scenario] * baseline[scenario]).toFixed(0)}</div>
                                <div>{((totalStats[scenario] * baseline[scenario]) / overallStats.totalPnL * 100).toFixed(1)}%</div>

                                <div className="pt-2">{cumulativeStats[index]}</div>
                                <div>{(cumulativeStats[index] / globalTotalTrades * 100).toFixed(1)}%</div>
                            </td>
                        ))}

                        <td className="border p-2">
                            <div>P/L = {overallStats.profits.toFixed(0)} / {overallStats.losses.toFixed(0)}</div>
                            <div>Factor = {(overallStats.profits / overallStats.losses).toFixed(2)}</div>
                            <div>PnL = {overallStats.totalPnL.toFixed(0)}</div>
                            <div>Avg PnL = {(overallStats.totalPnL / totalBacktests).toFixed(0)}</div>
                            <div>Med PnL = {medianPnL.toFixed(0)}</div>
                            <div>WinRate = {(overallStats.winRate / totalBacktests).toFixed(1)}%</div>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    {backtests.map((bt, index) => {
                        const { winRate, profitFactor, avgTrade, totalPnL, totalTrades, sumPnL, profits, losses } = calculateStats(bt, baseline);
                        return (
                            <tr key={index} className="border">
                                <td className="border p-2">
                                    <div>{index + 1}</div>
                                    <div>{bt.pair}</div>
                                    <div>{bt.tf}</div>

                                    </td>
                                {scenarios.map((scenario, i) => (
                                    <td key={scenario} className="border p-2">
                                        <div>{(bt[scenario] / totalTrades * 100).toFixed(0)}%</div>

                                        <div className="pt-2">{bt[scenario]}x{baseline[scenario].toFixed(1)}={totalPnL[i].toFixed(0)}</div>
                                        <div>{(totalPnL[i] / sumPnL * 100).toFixed(1)}%</div>
                                    </td>
                                ))}
                                <td className="border p-2">
                                    <div>P/L = {profits.toFixed(0)} / {losses.toFixed(0)}</div>
                                    <div>Factor = {profitFactor.toFixed(2)}</div>
                                    <div>PnL = {sumPnL.toFixed(0)}</div>
                                    <div>WinRate = {winRate.toFixed(1)}%</div>
                                    <div>Avg result = {avgTrade.toFixed(1)}</div>
                                </td>

                            </tr>
                        );
                    })}
                </tbody>

            </table>
        </div>
    );
};

export default BacktestTable;
