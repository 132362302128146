import { faArrowAltLeft, faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { Badge, Button, Col, Modal, ModalBody, Row } from 'reactstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ForexPair } from '../Screener/Screener';
import { TradelogContext } from './context';
import './styles.scss';
import { ConfidenceBadge, ResultBadge, StatusBadge, url } from './Tradelog';
const TradeSwiper = () => {
    const api = useContext(TradelogContext);
    const { trade_id, start_slide  } = useParams();
    const navigate = useNavigate();
    const swiperRef = useRef(null);
    const [modal, setModal] = useState(false);
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleModal = () => setModal(!modal);

    const currentTradeIndex = api.allTrades.findIndex((trade) => trade.trade_id === trade_id);

    const navigateToTrade = useCallback((index) => {
        const trade = api.allTrades[index];
        if (trade) {
            navigate(`/log/trade/${trade.trade_id}`);
        }
    }, [api.allTrades, navigate]);

    const handlers = useSwipeable({
        onSwipedUp: () => {
            if (currentTradeIndex < api.allTrades.length - 1) {
                navigateToTrade(currentTradeIndex + 1);
            }
        },
        onSwipedDown: () => {
            if (currentTradeIndex > 0) {
                navigateToTrade(currentTradeIndex - 1);
            }
        },
        preventDefaultTouchmoveEvent: true,
        trackTouch: true,
    });

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'ArrowUp' && currentTradeIndex > 0) {
            navigateToTrade(currentTradeIndex - 1);
            event.preventDefault();
        } else if (event.key === 'ArrowDown' && currentTradeIndex < api.allTrades.length - 1) {
            navigateToTrade(currentTradeIndex + 1);
            event.preventDefault();
        }
    }, [currentTradeIndex, api.allTrades, navigateToTrade]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            if (start_slide) {
                swiperRef.current.swiper.slideTo(parseInt(parseInt(start_slide) - 1));
            }
        }
    }, [trade_id, start_slide]);

    const trade = api.allTrades.find((t) => t.trade_id === trade_id);

    if (!trade) {
        return <div className="text-center text-white">Trade not found</div>;
    }

    const comments = [
        trade,
        {
            ...trade,
            snapshot_img_src: trade.last_snapshot_img_src,
            message: trade.last_message,
        }
    ]

    const handleImageClick = (imgSrc) => {
        setFullscreenImage(imgSrc);
        toggleModal();
    };

    return (
        <div {...handlers} className="trade-swiper">
            <div className="trade-item">
                <Row>
                    <Col md="6">
                        <h4>
                            <a href={url(trade)} target="_blank" rel="noreferrer" className="no-link">
                                {trade.exchange}: <ForexPair pair={trade.pair} />
                            </a>  <StatusBadge status={trade.status} />  <ResultBadge result={trade.result} />
                        </h4>
                        <div className="d-flex">
                            {trade.strategy && <Badge color="dark" className="me-2">{trade.strategy}</Badge>}
                            {trade.timeframe && <Badge color="dark" className="me-2">{trade.timeframe}</Badge>}
                            {trade.confidence && <ConfidenceBadge confidence={trade.confidence} />}
                            <div className="fw-bold opacity-70 text-white ms-2">{dayjs(trade.open_unix * 1000).format("HH:mm D MMM")}</div>
                        </div>
                    </Col>
                    <Col md="6" className="">
                        <div className="d-flex text-end">
                            <Button className="me-3 ms-auto" size="sm" color="success" outline onClick={() => api.setUpdateModal(trade)} ><FontAwesomeIcon icon={faPen} /> Edit</Button>
                            <Button onClick={() => navigate('/log')} color="light" outline size="sm"><FontAwesomeIcon icon={faArrowAltLeft} /> Close</Button>
                        </div>
                    </Col>
                </Row>
                <Swiper
                    spaceBetween={10}
                    ref={swiperRef}
                    initialSlide={start_slide ? parseInt(start_slide) : 0}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    keyboard={{ enabled: true, onlyInViewport: true }}
                    modules={[Pagination, Navigation, Keyboard]}
                    className="swiper-h"
                    onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)} // Update the activeIndex on slide change
                >
                    {comments.map((comment) => (
                        <SwiperSlide key={comment.comment_id}>
                            <div className="comment-slide text-white">
                                {comment.snapshot_img_src && (
                                    <div className="pt-3">
                                        <div className="img-background">

                                            <img
                                                alt="Snapshot"
                                                src={comment.snapshot_img_src}
                                                onClick={() => handleImageClick(comment.snapshot_img_src)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex justify-content-between">

                                    <div className="comment-details">
                                        {comment.message && <p className="mb-0 pt-3">{comment.message}</p>}
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <Modal isOpen={modal} toggle={toggleModal} className="fullscreen-modal" fullscreen>
                <ModalBody className="p-0">
                    {fullscreenImage && (
                        <img src={fullscreenImage} alt="Fullscreen" className="w-100 h-100" style={{ objectFit: 'contain' }} onClick={() => toggleModal()} />
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default TradeSwiper;
