import { createContext, useEffect, useState } from 'react';
// Import the functions you need from the SDKs you need
import dayjs from 'dayjs';
import { useContext, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FirebaseAuthContext } from '../SignIn';
import { callApi } from '../util';
// Create a Context for the Firebase token
export const TradelogContext = createContext({});

export const STATUS = [
    {
        value: "waiting",
        label: "Waiting for setup",
        color: "light",
        category: "open"
    },
    {
        value: "unfilled",
        label: "Unfilled / Order placed",
        color: "light",
        category: "open"
    },
    {
        value: "filled",
        label: "Filled / In the trade",
        color: "info",
        category: "open"
    },
    {
        value: "cancelled",
        label: "Cancelled / Didn't take",
        color: "light",
        category: "cancelled"
    },
    {
        value: "stop-loss",
        label: "SL hit",
        color: "danger",
        category: "closed"
    },
    {
        value: "managed-loss",
        label: "Managed loss",
        color: "danger",
        category: "closed"
    },
    {
        value: "managed-break-even",
        label: "Managed break even",
        color: "warning",
        category: "closed"
    },
    {
        value: "managed-profit",
        label: "Managed profit",
        color: "success",
        category: "closed"
    },
    {
        value: "take-profit",
        label: "TP hit",
        color: "success",
        category: "closed"
    },
];

const isClosingStatus = (status) => STATUS.filter(i => i.category === "closed").find(i => i.value === status);

export const STATUS_FILTER = [
    { value: "open", label: "All Open" },
    { value: "closed", label: "All Closed" },
    ...STATUS
]

export const STRATEGIES = [
    {
        value: "mean-reversion",
        label: "Mean Reversion / Over extension"
    },
    {
        value: "trend",
        label: "Buy the Dip / Sell the Rip"
    },
    {
        value: "momentum",
        label: "Momentum (Short Hold Time, Small Stop Loss Distance):"
    },
    {
        value: "breakout",
        label: "Breakout (Average Hold Time, Smaller Stop Loss Distance)"
    },
    {
        value: "range",
        label: "Range play"
    }
]

export const TIMEFRAME = [
    "15m", "1H", "4H", "D", "W"
]

export const CONFIDENCE_LEVEL = [
    "Low", "Medium", "High"
]

const SHOW_ALL = "SHOW_ALL";
const SHOW_FIRST_AND_LAST = "SHOW_FIRST_AND_LAST";
const SHOW_PREVIEW = "SHOW_PREVIEW";

export const VIEWS = {
    SHOW_PREVIEW,
    SHOW_ALL,
    SHOW_FIRST_AND_LAST,
}

const fetchTrades = async (user, filter) => {
    try {
        const data = await callApi(user, "GET", `/tradelog`, false, filter);
        console.log(data);
        const trades = data[0].sort((a, b) => b.open_unix - a.open_unix);
        const positions = data[1].sort((b, a) => b.create_timestamp - a.create_timestamp);
        return { trades,  positions };

    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return { trades: [], positions: [] }
};

const fetchOpenPositions = async (user) => {
    try {
        const data = await callApi(user, "GET", `/ctrader/open-positions`);
        return data;

    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return []

}

// const fetchComments = async (user, filter) => {
//     try {
//         const data = await callApi(user, "GET", `/tradelog/comments`, false, filter);
//         console.log("TRADELOG COMMENTS", data)
//         return data.sort((b, a) => b.unix - a.unix);

//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
//     return []
// };

const filterTrades = (trades, filter) => trades.filter(trade => {

    if (filter.status) {
        if (!STATUS.filter(i => i.category === filter.status || i.value === filter.status).map(i => i.value).includes(trade.status)) {
            return false;
        }
    }
    if (filter.pair && trade.pair !== filter.pair) {
        return false;
    }

    if (filter.strategy && trade.strategy !== filter.strategy) {
        return false;
    }
    return true;
});

export function TradelogProvider({ children }) {
    const user = useContext(FirebaseAuthContext); // Access the token
    const [allTrades, setTrades] = useState([]);
    const [positions, setPositions] = useState([]);
    const [openPositions, setOpenPositions] = useState([]);
    const [updateModal, setUpdateModal] = useState(false);
    const [view, setView] = useState(VIEWS.SHOW_PREVIEW);
    const [fullscreen, setFullscreen] = useState(false);
    const [pairs, setPairs] = useState([])
    const openPositionTimerRef = useRef(null);
    const params = useParams();
    const [filter, setFilter] = useState({
        fromMonth: -6,
        toMonth: 0,
    })

    const [isLoading, setIsLoading] = useState(false);

    const createTrade = async (data) => {

        const query = {
            ...data,
            open_date: data.open_date || dayjs().format("YYYY-MM-DD"),
            open_time: data.open_time || dayjs().format("HH:mm"),
            open_unix: data.open_unix || dayjs().unix(),
        }

        query.result = query.result ? parseFloat(query.result) : null;

        if(isClosingStatus(query.status)){
            query.closed_date = query.close_date || dayjs().format("YYYY-MM-DD");
            query.closed_time = query.close_time || dayjs().format("HH:mm");
            query.closed_unix = query.close_unix || dayjs().unix();
            query.duration = dayjs(query.close_unix).diff(dayjs(query.open_unix), 'minute');
        }

        setIsLoading(true);
        try {
            const data = await callApi(user, "POST", `/tradelog`, query);
            if (data) {
                setTrades(prev => [data, ...prev]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
        setUpdateModal(false);
    };

    const updateTrade = async (query) => {
        setIsLoading(true);
        try {
            const data = await callApi(user, "PUT", `/tradelog/${query.trade_id}`, query);
            if (data) {
                setTrades(prev => prev.map(trade => trade.trade_id === query.trade_id ? data : trade));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
        setUpdateModal(false);
    };

    const deleteTrade = async (trade_id) => {
        setIsLoading(true);
        try {
            const data = await callApi(user, "DELETE", `/tradelog/${trade_id}`);
            if (data) {
                setTrades(prev => prev.filter(trade => trade.trade_id !== trade_id));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
    }


    const refreshTradePositions = async (trade_id) => {
        try {
            const data = await callApi(user, "GET", `/ctrader/${trade_id}/refresh`);
            if (Array.isArray(data)) {
                setPositions(prev => [
                    ...prev.filter(position => position.trade_id !== trade_id), // Remove old positions for this trade_id
                    ...data // Add refreshed positions
                ]);
                return data;
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if(params?.trade_id){
            refreshTradePositions(params?.trade_id);
        }

    }, [params?.trade_id])


    useEffect(() => {
        // * reset frontend filter
        const getData = async () => {
            const fromUnix = dayjs().subtract(Math.abs(filter.fromMonth), 'month').startOf('month').unix(); // Left thumb
            const toUnix = dayjs().subtract(Math.abs(filter.toMonth), 'month').endOf('month').unix(); // Right thumb (current or selected month)
            const data = await fetchTrades(user, { fromUnix, toUnix });

            setTrades(data.trades);
            setPairs(Object.keys(data.trades.reduce((acc, i) => ({ ...acc, [i.pair]: true }), {})))
            setPositions(data.positions);
        }
        getData();
    }, [user, filter.fromMonth, filter.toMonth]);


    // * effect to fetch open positions every 30s
    useEffect(() => {
        if (openPositionTimerRef.current) {
            clearInterval(openPositionTimerRef.current);
        }

        const _fetchOpenPositions = async () => {
            const data = await fetchOpenPositions(user);
            setOpenPositions(data);
        }

        _fetchOpenPositions();
        openPositionTimerRef.current = setInterval(_fetchOpenPositions, 6000);

        return () => clearInterval(openPositionTimerRef.current);
    }, [user]);

    const closedPositions = useMemo(() => {
        return positions.filter(p => p.is_closed);
    }, [positions])

    const filteredTrades = useMemo(() => filterTrades(allTrades, filter), [allTrades, filter])

    const api = {
        allTrades,
        filteredTrades,
        updateModal,
        setUpdateModal,
        updateTrade,
        deleteTrade,
        fetchTrades,
        refreshTradePositions,
        createTrade,
        isLoading,
        view,
        setView,
        filter,
        setFilter,
        pairs,
        fullscreen,
        setFullscreen,
        closedPositions,
        openPositions,

    }


    return <TradelogContext.Provider value={api}> {children} </TradelogContext.Provider>
}