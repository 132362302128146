
import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row, Table } from 'reactstrap';

import { useRef } from "react";
import { Range, getTrackBackground } from "react-range";
import { roundToDecimals } from '../util';
import BacktestTable, { BACKTEST } from './Backtests';

export default function DCACalculator() {
    const [trade, setTrade] = useState([-1, 1]); // Default to pullback to -1 and tp at 1
    const [params, setParams] = useState({
        stoploss_level: -4.5,
        num_orders: 4,
        risk_per_order: 80,
        distance_between_orders: 1.1,

    });
    const [pullback, take_profit] = trade;
    const { stoploss_level, num_orders, risk_per_order, distance_between_orders } = params;
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const orders = [];
        for (let i = 0; i < num_orders; i++) {
            const entry_level = roundToDecimals(0 - i * distance_between_orders, 1);
            const order = {
                id: i + 1,
                stoploss: stoploss_level,
                risk: risk_per_order,
                entry_level,
                pnl_per_01: risk_per_order / (Math.abs(stoploss_level - entry_level) / 0.1),
            };
            orders.push(order);
        }
        setOrders(orders);
    }, [stoploss_level, num_orders, risk_per_order, distance_between_orders]);


    const ordersWithPnl = orders.map(i => {
        const { entry_level, pnl_per_01 } = i;
        const is_filled = pullback <= i.entry_level;
        const max_drawdown_distance = is_filled ? (pullback - entry_level) : 0;
        const max_drawdown = is_filled ? max_drawdown_distance / 0.1 * pnl_per_01 : 0;
        const pnl_distance = is_filled ? (take_profit - entry_level) : 0;
        const pnl = is_filled ? pnl_distance / 0.1 * pnl_per_01 : 0;
        return { ...i, is_filled, max_drawdown, max_drawdown_distance, pnl, pnl_distance };

    })

    const max_drawdown = ordersWithPnl.reduce((acc, order) => acc + order.max_drawdown, 0);
    const pnl = ordersWithPnl.reduce((acc, order) => acc + order.pnl, 0);
    const total_risk = num_orders * risk_per_order;


    return (
        <div>
            <Container className="text-white">

                <h1>DCA Calculator</h1>
                <p>Use this page to test out new setups</p>
                <h3 className="text-white py-3">Setup orders</h3>
                <Row className="align-items-center">
                    <Col md={3}>
                        <FormGroup>
                            <Label for="num_orders" className="text-white">
                                # Orders <span className="fw-bold">{num_orders}</span>
                            </Label>
                            <Input
                                id="num_orders"
                                name="range"
                                type="range"
                                value={num_orders}
                                min={0}
                                max={12}
                                step={1}
                                onChange={(e) => setParams(prev => ({ ...prev, num_orders: roundToDecimals(e.target.value, 0) }))}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="stoploss_level" className="text-white">
                                Stoploss level <span className="fw-bold">{stoploss_level}</span>
                            </Label>
                            <Input
                                id="stoploss_level"
                                name="range"
                                type="range"
                                value={stoploss_level}
                                min={-12}
                                max={0}
                                step={0.1}
                                onChange={(e) => setParams(prev => ({ ...prev, stoploss_level: roundToDecimals(e.target.value, 1) }))}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="risk_per_order" className="text-white">
                                Risk per Order <span className="fw-bold">{risk_per_order}</span>
                            </Label>
                            <Input
                                id="risk_per_order"
                                name="range"
                                type="range"
                                value={risk_per_order}
                                min={0}
                                max={500}
                                step={5}
                                onChange={(e) => setParams(prev => ({ ...prev, risk_per_order: roundToDecimals(e.target.value, 0) }))}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="distance_between_orders" className="text-white">
                                Distance between orders <span className="fw-bold">{distance_between_orders}</span>
                            </Label>
                            <Input
                                id="distance_between_orders"
                                name="range"
                                type="range"
                                value={distance_between_orders}
                                min={0}
                                max={5}
                                step={0.1}
                                onChange={(e) => setParams(prev => ({ ...prev, distance_between_orders: roundToDecimals(e.target.value, 1) }))}
                            />

                        </FormGroup>
                    </Col>
                </Row>
                <h3>Setup Trade</h3>
                <Row>
                    <Col>
                        <TradeSlider params={params} setTrade={setTrade} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>Orders</h3>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Entry level</th>
                                    <th>Risk</th>
                                    <th>PnL per 0.1</th>
                                    <th>Max Drawdown</th>
                                    <th>PnL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ordersWithPnl.map((order) => (
                                    <tr key={order.id}>
                                        <td>{order.id}</td>
                                        <td>{order.entry_level.toFixed(1)}</td>
                                        <td>{order.risk}</td>
                                        <td>{order.pnl_per_01.toFixed(2)}</td>
                                        <td>{order.max_drawdown ? order.max_drawdown.toFixed(2) : "-"}</td>
                                        <td>{order.pnl ? order.pnl.toFixed(2) : "-"}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="2">Total</td>
                                    <td className='fw-bold'>{total_risk.toFixed(0)}</td>
                                    <td></td>
                                    <td className='fw-bold'>{max_drawdown.toFixed(2)}</td>
                                    <td className='fw-bold'>{pnl.toFixed(2)}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h3>Levels and PnL</h3>
                        <Table borderless>
                            <thead>
                                <tr>
                                    <th className="text-center">Level</th>
                                    {ordersWithPnl.map((order) => (
                                        <th className="text-center" key={order.id}>Order {order.id} PnL</th>
                                    ))}
                                    <th className="text-center">Total PnL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(() => {
                                    // Generate levels from stoploss_level to take_profit in steps of 0.1
                                    const levels = [];
                                    for (let level = stoploss_level; level <= take_profit; level = roundToDecimals(level + 0.1, 1)) {
                                        levels.push(level);
                                    }

                                    // Calculate the PnL for each level
                                    return levels.map((level) => {
                                        let totalPnl = 0;

                                        const cells = ordersWithPnl.map((order) => {
                                            const { entry_level, pnl_per_01, is_filled } = order;
                                            const pnl =
                                                is_filled
                                                    ? (level - entry_level) / 0.1 * pnl_per_01
                                                    : 0; // Loss below entry level
                                            totalPnl += pnl;
                                            return <td key={`${order.id}-${level}`} className={pnl === 0 || level < pullback ? "text-white opacity-70 p-0 text-center" : (pnl > 0 ? "text-success p-0 text-center" : "text-danger p-0 text-center")}><small> {roundToDecimals(pnl, 2).toFixed(2)}</small></td>;
                                        });

                                        return (
                                            <tr key={level}>
                                                <td className={`p-0 text-center ${(level < pullback ? "opacity-70" : "")}`}><small>{level.toFixed(1)}</small></td>
                                                {cells}
                                                <td className={`fw-bold p-0 text-center  ${(level < pullback ? "opacity-70" : (totalPnl > 0 ? "text-success" : "text-danger"))} `}><small>{roundToDecimals(totalPnl, 2)}</small></td>
                                            </tr>
                                        );
                                    });
                                })()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <BacktestTable backtests={BACKTEST} defaultRisk={[80,80,80,80]} />
                    </Col>
                    <Col>
                        <BacktestTable backtests={BACKTEST} defaultRisk={[200, 120, 0,0]} />
                    </Col>
                </Row>
            </Container>
            <Container>

                <Row>
                    <Col>
                        <pre>
                            {JSON.stringify(ordersWithPnl, null, 2)}
                        </pre>
                        <pre>
                            {JSON.stringify(params, null, 2)}
                        </pre>
                        <pre>
                            {JSON.stringify(trade, null, 2)}
                        </pre>
                    </Col>
                </Row>
            </Container>
        </div>


    );
}

// Constants
const DEBOUNCE_DELAY = 100; // Debounce delay in milliseconds

const TradeSlider = ({ setTrade, params }) => {
    const [values, setValues] = useState([-1, 1]); // Default to last 6 months
    const debounceRef = useRef(null); // Reference for the debounce timer

    const handleRangeChange = (values) => {
        setValues(values);
    };


    // Debounced filtering function
    const setTradeData = () => {
        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
            setTrade(values.map(i => roundToDecimals(i, 1)));

        }, DEBOUNCE_DELAY);
    };

    useEffect(() => {
        setTradeData();

        // Clean up debounce on component unmount or when values change
        return () => clearTimeout(debounceRef.current);
    }, [...values]);

    return (
        <FormGroup>
            <Label className="text-white">
                {(values[0])} - {values[1]}
            </Label>

            <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                <Range
                    draggableTrack
                    values={values}
                    step={0.1}
                    min={-10}
                    max={3}
                    onChange={handleRangeChange}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: "36px",
                                display: "flex",
                                width: "100%",
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: "5px",
                                    width: "100%",
                                    borderRadius: "4px",
                                    background: getTrackBackground({
                                        values,
                                        colors: ["#ccc", "#548BF4", "#ccc"],
                                        min: -10,
                                        max: 1,
                                    }),
                                    alignSelf: "center",
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: "26px",
                                width: "26px",
                                borderRadius: "4px",
                                backgroundColor: "#FFF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px 2px 6px #AAA",
                            }}
                        >
                            <div
                                style={{
                                    height: "16px",
                                    width: "5px",
                                    backgroundColor: isDragged ? "#548BF4" : "#CCC",
                                }}
                            />
                        </div>
                    )}
                />
            </div>
        </FormGroup>
    );
};
